<template>
  <!-- personal:data START -->
  <div class="personal-data">
    <div class="description">
      <p v-html="`${$t('login.personalData')}`"></p>
    </div>

    <div class="is-half">
      <!-- form:register:form:gender START -->
      <el-form-item prop="gender">
        <el-select
          v-model="registerFormChild.gender"
          :placeholder="`${$t('login.gender.select')} *`"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        >
          <el-option
            v-for="item in gender"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- form:register:form:gender END -->

      <!-- form:register:form:title START -->
      <el-form-item prop="titel">
        <el-input
          :placeholder="$t('login.title')"
          v-model="registerFormChild.title"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        ></el-input>
      </el-form-item>
      <!-- form:register:form:title END -->
    </div>

    <!-- form:register:form:first-name START -->
    <el-form-item prop="firstName">
      <el-input
        :placeholder="`${$t('login.firstname')} *`"
        v-model="registerFormChild.firstName"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:first-name END -->

    <!-- form:register:form:last-name START -->
    <el-form-item prop="lastName">
      <el-input
        :placeholder="`${$t('login.lastname')} *`"
        v-model="registerFormChild.lastName"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:last-name END -->

    <!-- form:register:form:birth-date START -->
    <el-form-item prop="birthDate">
      <el-date-picker
        inputmode="none"
        v-model="registerFormChild.birthDate"
        :clearable=false
        type="date"
        format="dd.MM.yyyy"
        :placeholder="`${$t('login.birthdate')} *`"
        :picker-options="pickerOptions"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-date-picker>
    </el-form-item>
    <!-- form:register:form:birth-date END -->

    <!-- form:register:form:phone START -->
    <el-form-item prop="phone">
      <el-input
        inputmode="tel"
        :placeholder="`${$t('login.phone')} *`"
        v-model="registerFormChild.phone"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:phone END -->

    <!-- form:register:form:email START -->
    <el-form-item prop="email">
      <el-input
        inputmode="email"
        :placeholder="`${$t('login.email')} *`"
        v-model="registerFormChild.email"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:email END -->

    <!-- form:register:form:validate & calculate START -->
    <el-form-item>
      <el-button
        class="btn-default"
        type="primary"
        v-on:click="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      >{{ $t('login.continue') }}</el-button>
    </el-form-item>
    <!-- form:register:form:validate & calculate END -->

  </div>
  <!-- personal:data END -->
</template>

<script>
export default {
  name: 'personal-data',
  props: {
    registerForm: {
      type: Object,
    },

    validateStep: {
      type: Function,
    },

    executeRecaptcha: {
      type: Function,
    },

    globalErrorMessage: {
      type: Boolean,
    },

    progressActiveIndex: {
      type: Number,
    },

    pickerOptions: {
      type: Object,
    },
  },
  data() {
    return {
      registerFormChild: this.registerForm,
      progressActiveIndexChild: this.progressActiveIndex,
      gender: [
        {
          value: 1,
          label: this.$t('login.gender.male'),
        }, {
          value: 2,
          label: this.$t('login.gender.female'),
        }, {
          value: 3,
          label: this.$t('login.gender.diverse'),
        },
      ],
    };
  },
  mounted() {
    this.validateStep();
  },
};
</script>
